import {init} from "./init";

import config from 'appConfig';

console.log('IN CUSTOMER PORTAL', config);

init({
    modules : {
        annual : {
            routes: import(
                "./module/annual_data/annual-data.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        staff : {
            routes: import(
                /* webpackChunkName: "staff-module-route" */
                "./module/staff/staff.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        pesticide : {
            routes: import(
                "./module/pesticide/pesticide.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        client : {
            routes: import(
                "./module/client/client.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        land : {
            routes: import(
                "./module/land/land.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        seedlot : {
            routes: import(
                "./module/seedlot/seedlot.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        wd : {
            routes: import(
                "./module/week_data/wd.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        field : {
            routes: import(
                /* webpackChunkName: "field-route" */
                "./module/field_entry/field.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        user : {
            routes: import(
                "./module/user/user.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        fertilizer_application : {
            routes: import(
                "./module/fertilizer_application/fertilizer-application.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        customer_portal : {
            routes: import(
                "./module/customer_portal/customer-portal.route"
                ).then(({defineRoutes}) => {
                    return defineRoutes;
            })
        },
        equipment : {
            routes: import(
                "./module/equipment/equipment.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        report : {
            routes: import(
                "./module/report/report.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        },
        modus : {
            routes: import(
                "./module/modus/modus.route"
                ).then(({defineRoutes}) => {
                return defineRoutes;
            })
        }
    },
    /**
     * There are scripts we want to run on all pages. Here we hook into the start up function which triggers after
     * we have processed the module routes but no necessary when all the routes have completed initializing, this is because
     * each module has it's own way of handling itself
     * @return {Promise<{default: *} | never>}
     */
    onModuleLoaded : () => {
        return import(/* webpackChunkName: "startup" */"./startup").then(({default:startUp}) => {
            return startUp();
        })
    }
}); 
